// forms common logic
import { validateEmail } from "@/utils.js";

const validateRequired = v => !!v.length;

export default function() {

	const checkForm = function(fields) {
		var validate = true;
		const form = Object.entries(fields).map(key => key[1]);
		
		form.forEach(field => {
			field.errorMessage = [];
			if (field.required) {

				let value = field.type !== "checkbox"
					? field.value.trim()
					: field.value;

				if (!validateRequired(value)) {
					validate = false;
					field.errorMessage.push("Campo requerido");
					return;
				}
			}
			if (field.type == "email" && field.value.length) {
				if (!validateEmail(field.value.trim())) {
					validate = false;
					field.errorMessage.push("Añade un correo electrónico válido");
					return;
				}
			}
		})	
		return validate;
	}

	const checkSections = function(form) {
		var validateAll = true;
		form.forEach(section => {
			if (!section.optional || section.optional.value) {
				if (section.fields) {
					validateAll = !checkForm(section.fields) ? false : validateAll;		
				}
			}
		})
		return validateAll;
	}

	const capitalCase = function(string) {
		return string.toLowerCase().replace(/(?:^|\s)\S/g, a => a.toUpperCase());
	}

	return { checkForm, checkSections, capitalCase }
}