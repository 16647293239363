<template>
	<div class="form-field">
		<label class="form-field__label">
			{{ field.label }}
			<span class="form-field__required" v-if="field.required">*</span>
			<span class="form-field__optional" v-else>(opcional)</span>
		</label>
		<div class="form-select" v-if="field.type == 'select'">
			<select @change="update($event)">
				<option v-for="(option, optionIndex) in field.values" :key="optionIndex" :value="option" :selected="option == modelValue">
					{{ option || 'Selecciona una opción' }}
				</option>
			</select>
		</div>
		<div class="form-textarea" v-else-if="field.type == 'textarea'">
			<textarea @input="update($event)" :rows="field.rows"></textarea>
		</div>
		<div class="form-checkbox" v-else-if="field.type == 'checkbox'">
			<span v-for="(item, itemIndex) in field.items" :key="itemIndex">
				<input type="checkbox" :value="item" v-model="model"> {{ item }}
			</span>
		</div>	
		<div v-else>
			<input :type="field.type" :value="modelValue" @input="update($event)" :class="fieldClasses" v-focus="index">
		</div>
		<div class="form-field__help-text">
			<span class="form__error-message" v-if="fieldHasError">
				{{ field.errorMessage[0] }}
			</span>
			<span v-else-if="field.helpText">
				{{ field.helpText }}
			</span>
		</div>
	</div>
</template>

<script>
	import { Focus } from "@/directives.js";
	export default {
		name: "FormField",
		emits: ['update:modelValue'],
		props: {
			index: Number,
			field: Object,
			modelValue: { type: [String, Array, Boolean] }
		},
		directives: {
			focus: Focus
		}, 
		computed: {
			fieldHasError() {
				return this.field.errorMessage.length;
			},
			fieldClasses() {
				return {
					"form-field--error": this.fieldHasError
				}
			},
			model: {
				get() {
					return this.modelValue;
				},
				set(value) {
					this.$emit("update:modelValue", value);
				}
			}
		},
		methods: {
			update(e) {
				this.$emit("update:modelValue", e.target.value);
			}
		}
	}
</script>