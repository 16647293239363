<template>
		<div id="recaptcha" class="g-recaptcha" :data-sitekey="sitekey"></div>
</template>

<script>
	export default {
		name: "ReCaptchaV2",
		emits: ['verify'],
		data () {
			return {
				sitekey: '6LcSylgUAAAAAHV64p39ox6aqwFuenwF2C0jsaiy',
				widgetId: 0
			}
		},
		methods: {
			execute() {
				window.grecaptcha.execute(this.widgetId);
			},
			reset() {
      	window.grecaptcha.reset(this.widgetId);
    	},
			verifyCallback(token) {
				this.$emit('verify', token);
				this.reset();
			}
		},
		mounted() {
			if (window.grecaptcha) {
				window.grecaptcha.ready(() => {
					this.widgetId = window.grecaptcha.render('recaptcha', {
						sitekey: this.sitekey,
						size: 'invisible',
						callback: this.verifyCallback
					});
				});
			}
		}
	}
</script>
