<template>
	<div class="modal-backdrop">
		<div class="modal-wrapper">
			<div class="modal-processing">
				<img class="modal-processing__image" src="@/assets/procesando_zione_1.gif"/>
				<div class="modal-processing__content">
					<slot>Procesando...</slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default { 
		name: "ModalProcessing",
		mounted() {
			document.body.classList.add("dont-scroll");
		},
		unmounted() {
			document.body.classList.remove("dont-scroll");
		}
	}
</script>

<style scoped>
.modal-processing {
	background-color: #ffffff;
	margin: 0 auto;
	max-width: 340px;
	color: #26292c;
	padding: .75rem 2rem;
	border-radius: .5em;
	display: flex;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
}

.modal-processing__content {
	padding: 0 1rem 0 2rem;
	font-size: .9rem;
}

.modal-processing__image {
	height: 2.5rem;
}
</style>